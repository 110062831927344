<template>
  <div v-if="!isEmpty" class="bg-gray-100 dark:bg-zinc-800 p-6 md:p-4 rounded-md">
    <div class="max-w-7xl mx-auto px-0 md:px-4">
      <h2>{{ $t('Explore More') }}</h2>
      <div class="flex flex-col justify-between items-start">
        <div v-for="section of sections" :key="section.title">
          <div v-show="section.links.length > 0">
            <h3 class="text-2xl font-semibold mt-4 md:mt-4 mb-1 md:mb-1">{{ section.title }}</h3>
            <div class="flex flex-wrap">
              <div v-for="link of section.links" :key="link.url" class="mb-2">
                <NuxtLinkLocale :to="link.url" class="text-gray-600 dark:text-gray-300 mr-5 mb-4 underline">{{
                  link.title
                }}</NuxtLinkLocale>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  sections: {
    type: Array,
    required: true,
  },
});

//Check if any link is in the sections and if sections is > 0
const isEmpty = computed(() => {
  let empty = true;
  for (const section of props.sections) {
    if (section.links.length > 0) {
      empty = false;
      break;
    }
  }
  return empty;
});
</script>
